.sidebar{
  border: 1px solid #d3e0e9;
  border-radius: 3px;
}

.sidebar__section {
  padding: 20px;
}

.sidebar__section:first-child {
  padding-top: 20px;
}
.sidebar__section:last-child {
  padding-bottom: 20px;
}


.sidebar__section.notice--board {
  margin: -1px -16px;
  border-radius: 3px 3px 0 0;
}