@import "variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/simplemde/dist/simplemde.min";
/* Site specific styles */
@import "modules/sidebar";

.navbar-nav > li.admin-nav > a {
  background-color: #777;
  color: #f8f8f8;
}

@media (min-width: 768px) {
  .navbar-nav > admin-nav:first-child {
    margin-left: 40px;
  }
}

.notice--board {
  background-color: #3097d1;
  color: #fff;
}
.cal-date {
  font-weight: bold;
}

textarea {
  width: 100%;
  display: block;
}